import React, { useState } from 'react'
import InputField from '../../../../components/Yard/InputField/InputField';
import Date from '../../../../components/Yard/date/Date';
import Time from "../../../../components/Yard/time/Time";
const Part_c_container = () => {
    const [hour, setHour] = useState("Enter 8 hrs max")
    return (
        <>
            <div className="safety_part_c_container">
                <div className="part_c_permit">
                    <p>Name of User corresponding to User ID</p>
                    <h3>Dave Fondacaro</h3>
                </div>
                <div className="part_c_permit_time">
                    <h3>Period of time permit is authorised (8 hours max)</h3>
                    <InputField idName="part_C_id" className="paret_c_class" type="text" value={hour} change={setHour} />
                </div>
                <div className="part_c_date">
                    <h3>date</h3>
                    <Date />
                </div>
                <div className="part_c_time">
                    <div className="time-start">
                        <h3>Start time</h3>
                        <Time />
                    </div>
                    <div className="time-end">
                        <h3>End time</h3>
                        <Time />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Part_c_container;